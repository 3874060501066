import { useEffect, useRef, useState } from 'react'
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
} from '@chakra-ui/react'
//TODO declare type for menubutton and change it to typescript
//@ts-ignore
import MenuButton from 'components/slide-menu'
import { FaUserAlt, FaLock } from 'react-icons/fa'
import { getCsrfToken, signIn } from 'next-auth/react'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { useRouter } from 'next/router'
const CFaUserAlt = chakra(FaUserAlt)
const CFaLock = chakra(FaLock)

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [isSignupPage, setIsSignupPage] = useState(false)

  const [statusMessage, setStatusMessage] = useState(
    undefined as undefined | string
  )

  const handleShowClick = () => setShowPassword(!showPassword)

  const email = useRef('')
  const password = useRef('')
  const name = useRef('')

  const onSubmit = async () => {
    if (!email.current.length || !password.current.length) {
      setStatusMessage('Please fill up all required fields')
      return
    }

    await signIn('credentials', {
      username: email.current,
      password: password.current,
      redirect: true,
      callbackUrl: '/',
    })
  }

  const onSignup = async () => {
    if (
      !name.current.length ||
      !email.current.length ||
      !password.current.length
    ) {
      setStatusMessage('Please fill up all required fields')
      return
    }

    try {
      const result = await fetch(window.location.origin + '/api/new-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({
          name: name.current,
          email: email.current,
          password: password.current,
        }),
      })

      console.log(result)
      if (result.status == 200)
        setStatusMessage('Your singup was completed successfully')
      else
        setStatusMessage(
          'Failed to sign you up, please check your internet connection'
        )
    } catch (e) {
      console.log(e)
      setStatusMessage(
        'Failed to sign you up, please check your internet connection'
      )
    }
  }

  const [invalidCredentialsError, setInvalidCredentialsError] = useState(false)

  const router = useRouter()

  useEffect(() => {
    if (router?.query?.error === 'CredentialsSignin') {
      setInvalidCredentialsError(true)
      setStatusMessage('The provided email or password is invalid')
    }
  }, [router.isReady])

  useEffect(() => {
    console.log(isSignupPage)
  }, [isSignupPage])
  return (
    <Flex
      flexDirection='column'
      width='100wh'
      height='100vh'
      justifyContent='center'
      alignItems='center'
    >
      <MenuButton></MenuButton>
      <Stack
        flexDir='column'
        mb='2'
        justifyContent='center'
        alignItems='center'
      >
        <Avatar bg='teal.500' />
        <Heading color='teal.400'>Welcome</Heading>
        <Box minW={{ base: '90%', md: '468px' }}>
          <form>
            <Stack spacing={4} p='1rem' boxShadow='md'>
              <FormControl>
                {statusMessage && (
                  <FormHelperText paddingBottom={'1em'} color={'red'}>
                    {statusMessage}
                  </FormHelperText>
                )}
              </FormControl>

              {isSignupPage && (
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents='none'
                      children={<CFaUserAlt color='gray.300' />}
                    />

                    <Input
                      onChange={(value) => (name.current = value.target.value)}
                      name='name'
                      type='text'
                      placeholder='Full Name'
                    />
                  </InputGroup>
                </FormControl>
              )}

              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<CFaUserAlt color='gray.300' />}
                  />
                  <Input
                    onChange={(value) => (email.current = value.target.value)}
                    name='username'
                    type='email'
                    placeholder='email address'
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    color='gray.300'
                    children={<CFaLock color='gray.300' />}
                  />
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    placeholder='Password'
                    onChange={(value) =>
                      (password.current = value.target.value)
                    }
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleShowClick}>
                      {showPassword ? 'Hide' : 'Show'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign='right'>
                  <Link>forgot password?</Link>
                </FormHelperText>
              </FormControl>

              {isSignupPage ? (
                <Button
                  borderRadius={0}
                  variant='solid'
                  colorScheme='teal'
                  width='full'
                  onClick={onSignup}
                >
                  Sign Up
                </Button>
              ) : (
                <Button
                  borderRadius={0}
                  variant='solid'
                  colorScheme='teal'
                  width='full'
                  onClick={onSubmit}
                >
                  Login
                </Button>
              )}
            </Stack>
          </form>
        </Box>
      </Stack>

      {isSignupPage ? (
        <Box>
          Go back to login?{' '}
          <Link color='teal.500' onClick={() => setIsSignupPage(false)}>
            Login
          </Link>
        </Box>
      ) : (
        <Box>
          New to us?{' '}
          <Link color='teal.500' onClick={() => setIsSignupPage(true)}>
            Sign Up
          </Link>
        </Box>
      )}
    </Flex>
  )
}

export default LoginPage
